import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Col } from 'components/col/Col';

export default () => (
  <>
    <Helmet title="Um vefni" />

    <Intro>
      Um vefni
    </Intro>
    <Container>
      <Row>
        <Col col={8}>
        <p>
          Félag um átjándu aldar fræði gefur út tímaritið Vefni.
          Ritið er elsta rafræna fræðitímaritið sem gefið er út á Íslandi.
          Það hefur verið gefið út með hléum frá árinu 1998.
          Í Vefni eru birtar greinar eftir fræðimenn sem fást við viðfangsefni á sviði átjándu aldar fræða.
          Greinar í Vefni heyra einkum undir sagnfræði og bókmenntir.
        </p>
        <br />
        <p>
          Fræðigreinar þær sem birtar eru í Vefni eru gjarnan skrifaðar út frá fyrirlestrum sem haldnir hafa verð á málþingum Félags um átjándu aldar fræði.
          Ritstjórn Vefnis tekur einnig allt annað efni sem berst um átjándu aldar fræði til yfirlestrar með væntanlega birtingu í huga.
          Vefnir er ritrýnt tímarit.
          Allar greinar sem berast eru lesnar yfir af sérfróðum á viðkomandi sviði sem meta fræðigildi þeirra.
        </p>
        </Col>
      </Row>

    </Container>
  </>
);
